
/*
 *   File : nudge-preview.js
 *   Author URI : https://evoqins.com
 *   Description : Nudge preview which shows how the nudges are going to be displayed in mobile application.
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect } from "react";


// Custom styles
import style from "../../Styles/Components/nudge-preview.module.scss";
import Icon from "../Icon/icon";


const NudePreviewModal = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("nudge-preview");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
        //eslint-disable-next-line
    }, [props.close]);

    return (
        <Fragment>
            <div className={`modal fade ${style.e_modal}`}
                id="nudge-preview"
                tabIndex="-1"
                aria-labelledby="nudge-preview"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content padding-32px-lr padding-40px-top pb-0 ${style.e_content} `}>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                View nudge
                            </h5>
                            <Icon icon="close"
                                size={18}
                                id="close-modal"
                                data-bs-dismiss="modal"
                                className='cursor-pointer' />
                        </div>
                        <div className={`position-relative e-bg-ghost-white p-3 mb-3`}>
                            <img src={props.data.web_image}
                                alt="View notification"
                                height={120}
                                width={120}
                                className="object-fit-contain w-100" />
                            {/* <img src={props.data.logo_image}
                                        alt="Notification_image"
                                        width={12} /> */}

                            <p className="e-montserrat-semi-bold e-font-12 e-line-height-18 mb-0 color-granite-gray text-center pt-2">{props.data.title}</p>

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NudePreviewModal