/*
 *   File : dashboard.js
 *   Author : https://evoqins.com
 *   Description : Dashboard Container gives information about summary, aum, user, transaction of App  *   Version : 1.0.0
*/

// packages
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Custom components
import { CustomTitle } from "../../../Components/Title";
import { SummaryCard } from "../../../Components/Cards";
import { AreaChart, CustomBarChart } from "../../../Components/Chart";
import { CustomSelect } from "../../../Components/FormElements";
import { TextButton } from "../../../Components/Buttons";
import { DataTableContainer } from "../../../Components/DataTable";
import { EmptyScreen } from "../../../Components/Others";

// Custom styles
import Colors from '../../../Styles/color.module.scss';

import APIService from "../../../Services/api-service";

// filter options
const CUMULATIVE_OPTIONS = [
    {
        label: "Daily", value: 1
    },
    {
        label: "Weekly", value: 2
    },
    {
        label: "Monthly", value: 3
    }
]

// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "24px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-SemiBold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            cursor: "pointer",
            paddingLeft: "24px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },

        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px"
        }
    }
};


const Dashboard = () => {

    const navigate = useNavigate();
    const [selectedFilter, setSelectedFilter] = useState(CUMULATIVE_OPTIONS[0]);
    const [chartData, setChartData] = useState({});
    const [dashboardSummary, setDashboardSummary] = useState({});
    const [dashboardData, setDashboardData] = useState([]);
    const [cardLoader, setCardLoader] = useState(true);
    const [tableLoader, setTableLoader] = useState(true);
    const [chartLoader, setChartLoader] = useState(true);

    useEffect(() => {
        _getDashboardData();
        _getAUM();
    }, []);

    useEffect(() => {
        _getChartData();
        //eslint-disable-next-line
    }, [selectedFilter.value]);

    function _handleSelect(value) {
        setSelectedFilter(value);
    }

    function _handleNavigation() {
        navigate("/customer")
    }

    function _handleCustomerNavigate(row) {
        navigate("/customer/detail", {
            state: {
                id: row.customer_id,
                tab_index: 1,
                history: "dashboard"
            }
        });
    }

    // Table columns
    const TABLE_COLUMNS = [
        {
            name: "MINTIT ID",
            cell: row => row.mi_customer_id,
            width: '130px'
        },
        {
            name: "Name",
            cell: row => row.name,
            width: "180px"
        },
        {
            name: "Email ",
            cell: row => <a href={`mailto:${row.email}`}
                className="text-decoration-none color-dark-charcoal">{row.email}</a>,
            width: "280px"
        },
        {
            name: "Phone no",
            width: "180px",
            cell: row => <a href={`tel:${row.mobile}`}
                className="text-decoration-none color-dark-charcoal">{row.mobile}</a>,
        },
        {
            name: "Joined on",
            width: "180px",
            cell: row => row.joined_on
        },
        {
            name: "AUM",
            width: "180px",
            cell: row => row.aum_text
        },
    ]

    // API - get chart data
    const _getChartData = () => {
        const url = "/dashboard/cumulative-performance";
        const request = {
            filter_id: selectedFilter.value
        }
        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setChartData(response.data);
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setChartLoader(false);
        })
    }

    // API - get Dashboard data
    const _getDashboardData = () => {
        const url = "/dashboard/get";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setDashboardSummary(response.data);
            }
            setCardLoader(false);
        });
    }

    // API - get Dashboard data
    const _getAUM = () => {
        const url = "/dashboard/get-customer-aum";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setDashboardData(response.data);
            }
            setTableLoader(false);
        });
    }

    const Loading = () => {
        return (
            <div className="h-80vh d-flex align-items-center justify-content-center">
                <img src={require("../../../Assets/Images/loader/page-loader.gif")}
                    alt={"loader"}
                    width={60}
                    height={60}
                />
            </div>
        )
    }


    return (


        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Dashboard" type={1} />
            </div>

            <div className="e-body-wrapper">

                {/* -------- Summary-------- */}
                {
                    cardLoader === true ?
                        <Loading />
                        :
                        Object.keys(dashboardSummary).length === 0 ?
                            <EmptyScreen className="my-5"
                                title="No data found"
                                image={require("../../../Assets/Images/loader/empty-funds.png")}
                                description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                            :
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-12 mb-3">
                                    <SummaryCard icon={require("../../../Assets/Images/Dashboard/aum.svg").default}
                                        title="Total AUM"
                                        countText={dashboardSummary.aum_text}
                                        count={dashboardSummary.aum}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mb-3">
                                    <SummaryCard icon={require("../../../Assets/Images/Dashboard/aum.svg").default}
                                        title="Invested Amount"
                                        countText={dashboardSummary.invested_amount_text}
                                        count={dashboardSummary.invested_amount}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mb-3">
                                    <SummaryCard icon={require("../../../Assets/Images/Dashboard/total-users.svg").default}
                                        title="Total number of users"
                                        countText={dashboardSummary.total_users}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mb-3">
                                    <SummaryCard icon={require("../../../Assets/Images/Dashboard/total-transactions.svg").default}
                                        title="Total transactions"
                                        countText={dashboardSummary.no_of_transactions}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mb-3">
                                    <SummaryCard icon={require("../../../Assets/Images/Dashboard/pending-transactions.svg").default}
                                        title="Pending transactions"
                                        countText={dashboardSummary.pending_transactions}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mb-3">
                                    <SummaryCard icon={require("../../../Assets/Images/Dashboard/invested-users.svg").default}
                                        title="Invested users"
                                        countText={dashboardSummary.invested_user}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mb-3">
                                    <SummaryCard icon={require("../../../Assets/Images/Dashboard/non-invested-users.svg").default}
                                        title="Non-invested users"
                                        countText={dashboardSummary.non_invested_user}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mb-3">
                                    <SummaryCard icon={require("../../../Assets/Images/Dashboard/non-invested-users.svg").default}
                                        title="Non-verified users"
                                        countText={dashboardSummary.non_verified_user}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mb-3">
                                    <SummaryCard icon={require("../../../Assets/Images/Dashboard/average-aum.svg").default}
                                        title="Average AUM/ user"
                                        countText={dashboardSummary.average_aum_text}
                                        count={dashboardSummary.average_aum}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mb-3">
                                    <SummaryCard icon={require("../../../Assets/Images/Dashboard/invested-goals.svg").default}
                                        title="Created/ Invested goals"
                                        countText={dashboardSummary.invested_goals}
                                    />
                                </div>

                                <div className="col-lg-3 col-md-6 col-12 mb-3">
                                    <SummaryCard icon={require("../../../Assets/Images/Funds/strength.png")}
                                        title="Financial Pyramid updated"
                                        countText={dashboardSummary.financial_pyramid_updated}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mb-3">
                                    <SummaryCard icon={require("../../../Assets/Images/Funds/strength.png")}
                                        title="Financial Pyramid not updated"
                                        countText={dashboardSummary.financial_pyramid_not_updated}
                                    />
                                </div>
                            </div>
                }

                {/* --------Cumulative performance-------- */}
                <div className="row mt-3">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <CustomTitle title="Cumulative performance" type={2} />

                        <CustomSelect
                            type={2}
                            value={selectedFilter}
                            options={CUMULATIVE_OPTIONS}
                            isSearchable={false}
                            selectChange={_handleSelect} />
                    </div>
                    {
                        chartLoader === true ?
                            <Loading />
                            :
                            Object.keys(chartData).length === 0 ?
                                <EmptyScreen className="my-5"
                                    title="No data found"
                                    image={require("../../../Assets/Images/loader/empty-funds.png")}
                                    description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                :
                                <Fragment>

                                    <div className="col-lg-6  margin-32px-bottom">
                                        <div className='e-bg-white e-box-shadow-black p-4 border-radius-16px'>
                                            <h4 className='mb-0 e-montserrat-semi-bold e-font-14 e-line-height-18 mb-3'>
                                                AUM
                                            </h4>
                                            <AreaChart
                                                data={chartData.aum.data}
                                                labels={chartData.aum.label} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6  margin-32px-bottom">
                                        <div className='e-bg-white e-box-shadow-black p-4 border-radius-16px'>
                                            <h4 className='mb-0 e-montserrat-semi-bold e-font-14 e-line-height-18 mb-3'>
                                                New user registrations
                                            </h4>
                                            <CustomBarChart
                                                data={chartData.users.data}
                                                labels={chartData.users.label} />
                                        </div>
                                    </div>
                                </Fragment>
                    }
                </div>

                {/* =========== Top Customers =========== */}
                <div className="row">
                    <div className="col-12">
                        <div className='e-bg-white e-box-shadow-black py-4 border-radius-16px margin-32px-bottom'>
                            {tableLoader === true ?
                                <Loading />
                                :
                                dashboardData.length === 0 ?
                                    <EmptyScreen className="my-5"
                                        title="No data found"
                                        image={require("../../../Assets/Images/loader/empty-funds.png")}
                                        description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                    :
                                    <Fragment>
                                        <div className="d-flex justify-content-between align-items-center px-4">
                                            <CustomTitle title="Top customers based on AUM" type={2} />
                                            <TextButton name="View all" onPress={_handleNavigation} />
                                        </div>
                                        <div className="mt-4" >
                                            <DataTableContainer data={dashboardData}
                                                columns={TABLE_COLUMNS}
                                                customStyles={TABLE_STYLE}
                                                rowClick={_handleCustomerNavigate} />
                                        </div>
                                    </Fragment>
                            }
                        </div>
                        {/* <div className='e-bg-white e-box-shadow-black py-4 border-radius-16px'>
                            <div className="d-flex justify-content-between px-4 align-items-center">
                                <CustomTitle title="Top customers opted for rebalance" type={2} />
                                <TextButton name="View all" onPress={_handleNavigation} />
                            </div>
                            <div className="mt-4" >
                                <DataTableContainer data={TABLE_DATA}
                                    columns={TABLE_COLUMNS}
                                    customStyles={TABLE_STYLE} />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Dashboard