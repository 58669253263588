/*
 *   File : listing.js
 *   Author : https://evoqins.com
 *   Description : Customer list
 *   Version : 1.0.0
*/

// packages
import { Fragment, useCallback, useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

// Custom components
import { CustomTitle } from "../../../Components/Title";
import { InfoCard } from "../../../Components/Cards";
import { DataTableContainer } from "../../../Components/DataTable";
import { Icon } from '../../../Components/Icon';
import { Badge } from "../../../Components/Badge";
import { CustomSelect, CustomTextInput, SearchInput } from "../../../Components/FormElements";
import { PrimaryButton, DownloadButton, SecondaryButton, LinkButton } from "../../../Components/Buttons";
import { EmptyScreen } from "../../../Components/Others";
import { DeleteConfirmationModal } from "../../../Components/Modal";

// Custom styles
import Colors from '../../../Styles/color.module.scss';

// Service, Helper
import APIService from "../../../Services/api-service";
import Config from "../../../Helper/config";
import { _forceNavigate } from "../../../Helper/helper";

// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "24px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            paddingLeft: "24px",
            borderBottom: 'none !important',

            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },
            '&:hover': {
                backgroundColor: Colors.lavender_mist
            },

        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            userSelect: "none",
            cursor: "pointer",
            height:"80px"
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px",
            paddingTop: "10px",
            borderTop: "none"
        }
    }
};

const PHONE_VERIFIED_OPTIONS = [
    {
        label: "Yes",
        value: true
    },
    {
        label: "No",
        value: false
    }
]

const CustomerListing = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [search, setSearch] = useState("");
    const [showTooltip, setShowTooltip] = useState(false);
    const [aumFrom, setAumFrom] = useState("");
    const [aumTo, setAumTo] = useState("");
    const [ageFrom, setAgeFrom] = useState("");
    const [ageTo, setAgeTo] = useState("");
    const [fundStatusOptions, setFundStatusOptions] = useState([]);
    const [fundStatus, setFundStatus] = useState(null);

    const [pyramidStatusOptions, setPyramidStatusOptions] = useState([]);
    const [pyramidStatus, setPyramidStatus] = useState(null);

    const [riskProfileOptions, setRiskProfileOptions] = useState([]);;
    const [riskProfile, setRiskProfile] = useState(null);
    const [sourceOptions, setSourceOptions] = useState([]);;
    const [source, setSource] = useState(null);
    const [kycOptions, setKycOptions] = useState([]);;
    const [kyc, setKyc] = useState(null);
    const [genderOptions, setGenderOptions] = useState([]);;
    const [gender, setGender] = useState(null);
    const [stateOptions, setStateOptions] = useState([]);;
    const [state, setState] = useState(null);
    const [cityOptions, setCityOptions] = useState([]);;
    const [city, setCity] = useState(null);
    const [investmentStatusOptions, setInvestmentStatusOptions] = useState([]);;
    const [investmentStatus, setInvestmentStatus] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [bankVerificationPending, setBankVerificationPending] = useState(null);
    const [bankVerificationOptions, setBankVerificationOptions] = useState([]);

    const [phoneVerified, setPhoneVerified] = useState(PHONE_VERIFIED_OPTIONS[0]);

    const [customerData, setCustomerData] = useState({});

    const [filterEnabled, setFilterEnabled] = useState(false);
    const [tableLoader, setTableLoader] = useState(true);
    const [filterLoader, setFilterLoader] = useState(true);
    const [filter, setFilter] = useState(false);
    const [summaryData, setSummaryData] = useState({});
    const [screenLoader, setScreenLoader] = useState(true);
    const [locationLoader, setLocationLoader] = useState(true);

    const [selectAll, setSelectAll] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState(new Set());
    const [rowSelectFalse, setRowSelectFalse] = useState(false);

    const [downloadLoader, setDownloadLoader] = useState(false);
    const [filterApplied, setFilterApplied] = useState(false);
    const [bankStatus, setBankStatus] = useState(null);
    const [pendingLoader, setPendingLoader] = useState(false);
    const [isAccept, setIsAccept] = useState(false);


    const [allFilterData, setAllFilterData] = useState({
        page_num: 1,
        aum_from: "",
        aum_to: "",
        age_from: "",
        age_to: "",
        external_fund_status: null,
        risk_profile: null,
        income_source: null,
        kyc_status: null,
        gender: null,
        state: null,
        city: null,
        investment_status: null,
        query: "",
        pyramid_status: null,
        bank_verification_pending: null,
        phone_verification_pending: PHONE_VERIFIED_OPTIONS[0]
    });

    _forceNavigate(() => {
        // eslint-disable-next-line
        if (location.pathname == "/customer") {
            navigate(-3)
        }
    });

    const _updateFilterData = useCallback((updates) => {
        setAllFilterData(prevState => ({
            ...prevState,
            ...updates
        }));
    }, []);

    useEffect(() => {
        if (location.state !== null && Object.keys(location.state).length !== 0) {
            if (location.state.query) {
                setSearch(location.state.query)
            }
            _updateFilterData({
                page_num: location.state.page_num,
                aum_from: location.state.aum_from,
                aum_to: location.state.aum_to,
                age_from: location.state.age_from,
                age_to: location.state.age_to,
                query: location.state.query,
                external_fund_status: location.state.external_fund_status,
                risk_profile: location.state.risk_profile,
                income_source: location.state.income_source,
                kyc_status: location.state.kyc_status,
                gender: location.state.gender,
                state: location.state.state,
                city: location.state.city,
                investment_status: location.state.investment_status,
                pyramid_status: location.state.pyramid_status,
                bank_verification_pending: location.state.bank_verification_pending,
                phone_verification_pending: location.state.phone_verification_pending
            });
            setPageNumber(location.state.page_num);
        }
    }, [location, _updateFilterData]);

    useEffect(() => {
        _getFilterData();
        _getUserSummary();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (bankStatus !== null) {
            const modal = new Modal(document.getElementById("delete-modal"));
            modal.show();
        }
    }, [bankStatus])

    useEffect(() => {
        if (filter === true) {
            _validate();
        }
        // eslint-disable-next-line
    }, [filter]);

    useEffect(() => {
        if (locationLoader === false) {
            _getUserList();
        }
        // eslint-disable-next-line
    }, [pageNumber, locationLoader]);

    // for deselecting header checkbox in the table
    useEffect(() => {
        if (rowSelectFalse === true) {
            setSelectAll(false);
        }
    }, [rowSelectFalse])

    useEffect(() => {
        if (selectAll === true && Object.keys(customerData).length !== 0) {
            const customer_id_set = new Set(customerData.data.map((item) => item.user_id));
            setSelectedUsers((prevSet) => new Set([...prevSet, ...customer_id_set]));
            setRowSelectFalse(false);
        }
        else {
            if (rowSelectFalse === false) {
                setSelectedUsers(new Set());
            }
        }
        // eslint-disable-next-line
    }, [selectAll, customerData])

    function _handleNavigate(row) {
        navigate("/customer", {
            state: {
                id: row.user_id,
                history: "customer",
                ...allFilterData
            }
        }, { replace: true });
        setTimeout(() => {
            navigate("detail", {
                state: {
                    id: row.user_id,
                    history: "customer",
                    ...allFilterData
                }
            }, { replace: true });
        }, 0);
    }

    const _validate = () => {
        let valid = true;
        let error = "";

        // Validate age range
        if (ageTo.length !== 0) {
            if (parseInt(ageFrom, 10) < 18) {
                error = "From age should be a valid number above 18";
                valid = false;
            } else if (parseInt(ageFrom, 10) > parseInt(ageTo, 10)) {
                error = "From age should be less than or equal to To age";
                valid = false;
            }
        }

        if (ageFrom.length !== 0) {
            // Validate To age
            if (ageTo === "" || isNaN(parseInt(ageTo, 10)) || parseInt(ageTo, 10) > 100) {
                error = "To age should be a valid number less than 100";
                valid = false;
            }
        }
        // Validate AUM range
        if (aumTo.length !== 0) {
            if (aumFrom.length === 0) {
                error = "Enter a valid AUM from value greater than 0";
                valid = false;
            }
            else if (parseInt(aumFrom, 10) > parseInt(aumTo, 10)) {
                error = "AUM from should be less than AUM to";
                valid = false;
            }

        }
        if (aumFrom.length !== 0) {
            if (aumTo.length === 0) {
                error = "Enter a valid AUM to value greater than 0";
                valid = false;
            }
            else if (parseInt(aumTo, 10) < parseInt(aumFrom, 10)) {
                error = "AUM to should be greater than AUM from";
                valid = false;
            }
        }

        if (valid === true) {
            _getUserList();
            setTableLoader(true);
            setFilterEnabled(false);
        }
        else {
            toast.dismiss();
            toast.error(error, {
                type: "error",
            });
            setFilter(false);
        }
    }

    function _handleSearch(search_value) {
        setSearch(search_value);
        _updateFilterData({ page_num: 1 })
        if (search_value === "") {
            _getUserList({ clearSearch: "" });
        }
    }

    function _handleToggleFilter() {
        setFilterEnabled(!filterEnabled)
    }

    function _handleTooltip(status) {
        setShowTooltip(status);
    }

    function _handleAum(input_value, type) {
        switch (type) {
            case 1:
                setAumFrom(input_value);
                break;
            case 2:
                setAumTo(input_value);
                break;
            default:
                break;
        }
    }

    function _handleFundStatus(select_value) {
        setFundStatus(select_value);
    }

    function _handlePyramidStatus(select_value) {
        setPyramidStatus(select_value);
    }

    function _handleBankVerificationStatus(select_value) {
        setBankVerificationPending(select_value);
    }

    function _handlePhoneVerification(select_value) {
        setPhoneVerified(select_value);
    }

    function _handleRiskProfile(select_value) {
        setRiskProfile(select_value);
    }

    function _handleSourceIncome(select_value) {
        setSource(select_value);
    }

    function _handleKycStatus(select_value) {
        setKyc(select_value);
    }

    function _handleAge(input_value, type) {
        switch (type) {
            case 1:
                if (input_value >= 100) {
                    setAgeFrom(100);
                }
                else {
                    setAgeFrom(input_value);
                }
                break;
            case 2:
                if (input_value >= 100) {
                    setAgeTo(100);
                }
                else {
                    setAgeTo(input_value);
                }
                break;
            default:
                break;
        }
    }

    function _handleGender(select_value) {
        setGender(select_value);
    }

    function _handleState(select_value) {
        setState(select_value);
    }

    function _handleCity(select_value) {
        setCity(select_value);
    }

    function _handleInvestmentStatus(select_value) {
        setInvestmentStatus(select_value);
    }

    function _handleBankStatus(data, accept) {
        setBankStatus(data);
        setIsAccept(accept);
    }
    const _handleRowCheckBox = (row, event) => {
        // console.log("Row", row);
        event.stopPropagation();
        // console.log("before", selectedUsers)
        if (selectedUsers.has(row.user_id)) {
            setSelectedUsers((prevCustomerIds) => {
                const new_customer_ids = new Set(prevCustomerIds);
                new_customer_ids.delete(row.user_id);
                return new_customer_ids;
            });
        }
        else {
            setSelectedUsers((prevCustomerIds) => new Set([...prevCustomerIds, row.user_id]));
        }
        setRowSelectFalse(true);
    }

    const _handleSelectAll = (event) => {
        event.stopPropagation();
        setSelectAll(!selectAll)
    }

    // 
    // User columns
    const USER_COLUMNS = [
        {
            name: <div className={`w-fit-content d-flex gap-12px align-items-center`}>
                {/* Add your custom image or icon here */}
                <Icon icon={selectAll === true ? "checked" : "unchecked"}
                    size={18}
                    className="cursor-pointer"
                    onClick={(event) => _handleSelectAll(event)} />

                <p className="mb-0">MINTIT ID</p>
            </div>,
            selector: row => row.mi_customer_id,
            width: '170px',
            cell: row =>
                <div className="d-flex align-items-center gap-12px" onClick={() => _handleNavigate(row)}>

                    <Icon icon={selectedUsers.has(row['user_id']) ? "checked" : "unchecked"}
                        size={18}
                        onClick={(event) => _handleRowCheckBox(row, event)} />


                    <p className="color-dark_charcoal e-montserrat-medium e-font-14 e-line-height-24 mb-0 mt-0">{row.mi_customer_id === null ? "-" : row.mi_customer_id}</p>
                </div>
        },
        {
            name: "FP ID",
            selector: row => row.fp_id === null ? "-" : row.fp_id,
            width: '100px'
        },
        {
            name: "Name",
            cell: row => row.name === null ? "-" : row.name,
            width: "160px"
        },
        {
            name: "Last login",
            cell: row => row.last_logined_at ? row.last_logined_at : "-",
            width: "160px"
        },
        {
            name: "IP Address",
            cell: row => row.ip_address ? row.ip_address : "-",
            width: "160px"
        },
        {
            name: "Investment status",
            selector: row => row.investment_status === null ? "-" : <Badge status={row.investment_status}
                color={row.investment_status === "Sign up" ? Config.BADGE_COLORS.blue :
                    row.investment_status === 'Ready to invest' ? Config.BADGE_COLORS.orange :
                        row.investment_status === "Invested" ? Config.BADGE_COLORS.green :
                            Config.BADGE_COLORS.magenta}
                onClick={() => _handleNavigate(row)} />,
            width: "190px"
        },
        {
            name: "KYC type ",
            selector: row => row.kyc_type === null ? "-" : row.kyc_type,
            width: "100px"
        },
        {
            name: "AUM",
            selector: row => row.aum_text === null ? "-" : row.aum_text,
            width: "150px"
        },
        {
            name: "P&L",
            cell: row => row['p_&_l'].length === 0 ? "-" : <span className={row[`p_&_l`] > 0 ? "color-emerald-green" : row[`p_&_l`] < 0 ? "color-deep-carmine-pink" : "color-eerie-black"}>{row['p_&_l_text']}</span>,
            width: "150px"
        },
        {
            name: "Risk profile",
            width: "140px",
            selector: row => row.risk_profile ? row.risk_profile : "-"
        },
        {
            name: "Total orders",
            width: "140px",
            selector: row => row.total_orders ? row.total_orders : "-",
        },
        {
            name: "Age",
            width: "80px",
            selector: row => row.age === null ? "-" : row.age,
        },
        {
            name: "Gender",
            width: "120px",
            selector: row => row.gender === null ? "-" : row.gender
        },
        {
            name: "Source of income",
            width: "160px",
            selector: row => row.source_of_income === null ? "-" : row.source_of_income
        },
        {
            name: "City",
            width: "160px",
            selector: row => row.city === null ? "-" : row.city
        },
        {
            name: "State",
            width: "160px",
            selector: row => row.state === null ? "-" : row.state
        },
        {
            name: "Email",
            width: "220px",
            cell: row => row.email === null ? "-" : <a href={`mailto:${row.email}`} className="color-dark-charcoal" >{row.email}</a>,
        },
        {
            name: "Phone",
            width: "160px",
            selector: row => row.phone === null ? "-" : <a href={`mailto:${row.phone}`} className="color-dark-charcoal" >{row.phone}</a>
        },
        {
            name: "Bank",
            width: "180px",
            cell: row => row.bank_name === null ? "-" : row.bank_name
        },
        {
            name: "External fund imported",
            width: "200px",
            cell: row => row.externanal_fund_import === true ? "Yes" : "No"
        },
        {
            name: "Joined on",
            width: "160px",
            selector: row => row.joined === null ? "-" : row.joined
        },

        {
            name: "Bank verification pending",
            width: "220px",
            selector: row => row.bank_verification_pending === true ?
                <div className="d-flex gap-12px">
                    <LinkButton text="Accept"
                        handleClick={() => _handleBankStatus(row, true)} />
                    <LinkButton text="Reject" className="color-deep-carmine-pink"
                        handleClick={() => _handleBankStatus(row, false)} />
                </div>
                :
                "No"
        },
    ]

    const _handleFilter = () => {
        setFilter(true);
        setPageNumber(1);
        _updateFilterData({
            page_num: 1,
            aum_from: aumFrom,
            aum_to: aumTo,
            external_fund_status: fundStatus,
            risk_profile: riskProfile,
            income_source: source,
            kyc_status: kyc,
            age_from: ageFrom,
            age_to: ageTo,
            gender: gender,
            state: state,
            city: city,
            pyramid_status: pyramidStatus,
            investment_status: investmentStatus,
            bank_verification_pending: bankVerificationPending,
            phone_verification_pending: phoneVerified
        });
    };

    const _handlePageChange = (page) => {
        setPageNumber(page);
        setTableLoader(true);
        _updateFilterData({ page_num: page });
    };

    const _handleClear = () => {
        setFundStatus(fundStatusOptions[0]);
        setPyramidStatus(pyramidStatusOptions[0]);
        setRiskProfile(riskProfileOptions[0]);
        setSource(sourceOptions[0]);
        setKyc(kycOptions[0]);
        setGender(genderOptions[0]);
        setState(stateOptions[0]);
        setCity(cityOptions[0]);
        setInvestmentStatus(investmentStatusOptions[0]);
        setAgeFrom("");
        setAgeTo("");
        setAumFrom("");
        setAumTo("");
        setBankVerificationPending(bankVerificationOptions[0]);
        setPhoneVerified(PHONE_VERIFIED_OPTIONS[0]);

    }

    // API - get filter data
    const _getFilterData = useCallback(() => {
        const url = "/user/filter-data";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                const locationState = location.state || {};
                const responseData = response.data;

                const filterData = {
                    external_fund_status: locationState.external_fund_status || responseData.external_fund_status[0],
                    risk_profile: locationState.risk_profile || responseData.risk[0],
                    income_source: locationState.income_source || responseData.source_of_income[0],
                    kyc_status: locationState.kyc_status || responseData.kyc_status[0],
                    gender: locationState.gender || responseData.gender[0],
                    state: locationState.state || responseData.state[0],
                    city: locationState.city || responseData.city[0],
                    investment_status: locationState.investment_status || responseData.investment_status[0],
                    pyramid_status: locationState.pyramid_status || responseData.pyramid_status[0],
                    bank_verification_pending: locationState.bank_verification_pending || responseData.bank_verification[0],
                    phone_verification_pending: locationState.phone_verification_pending || PHONE_VERIFIED_OPTIONS[0],
                };

                _updateFilterData(filterData);
                setFundStatusOptions(responseData.external_fund_status);
                setPyramidStatusOptions(responseData.pyramid_status);
                setRiskProfileOptions(responseData.risk);
                setSourceOptions(responseData.source_of_income);
                setKycOptions(responseData.kyc_status);
                setGenderOptions(responseData.gender);
                setStateOptions(responseData.state);
                setCityOptions(responseData.city);
                setInvestmentStatusOptions(responseData.investment_status);
                setBankVerificationOptions(responseData.bank_verification);
                setFilterLoader(false);
                setLocationLoader(false);
            }
        });
    }, [location.state, _updateFilterData]);

    // API - get user list
    const _getUserList = ({ clearSearch = null } = {}) => {

        const url = "/user/list";
        const request = {
            page_num: allFilterData.page_num,
            page_size: 12,
            aum_from: allFilterData.aum_from.length === 0 ? null : allFilterData.aum_from,
            aum_to: allFilterData.aum_to.length === 0 ? null : allFilterData.aum_to,
            age_from: allFilterData.age_from.length === 0 ? null : allFilterData.age_from,
            age_to: allFilterData.age_to.length === 0 ? null : allFilterData.age_to,
            external_fund_status: allFilterData.external_fund_status === null ? null : allFilterData.external_fund_status.value,
            risk_profile: allFilterData.risk_profile === null ? null : allFilterData.risk_profile.value,
            income_source: allFilterData.income_source === null ? null : allFilterData.income_source.value,
            kyc_status: allFilterData.kyc_status === null ? null : allFilterData.kyc_status.value === 1 ? true : allFilterData.kyc_status.value === 2 ? false : null,
            gender: allFilterData.gender === null ? null : allFilterData.gender.value,
            state: allFilterData.state === null ? null : allFilterData.state.value,
            city: allFilterData.city === null ? null : allFilterData.city.value,
            investment_status: allFilterData.investment_status === null ? null : allFilterData.investment_status.value,
            pyramid_status: allFilterData.pyramid_status === null ? null : allFilterData.pyramid_status.value,
            query: clearSearch !== null ? clearSearch.trim() : allFilterData.query.trim(),
            bank_verification_pending: allFilterData.bank_verification_pending === null ? null : allFilterData.bank_verification_pending.value,
            phone_verified: allFilterData.phone_verification_pending === null ? null : allFilterData.phone_verification_pending.value
        };

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                if (clearSearch !== null) {
                    _updateFilterData({ query: clearSearch });
                    setSearch(clearSearch);
                }
                setCustomerData(response.data);
                if (allFilterData.aum_from.length !== 0 || allFilterData.aum_to.length !== 0 || allFilterData.age_from.length !== 0 || allFilterData.age_to.length !== 0 || allFilterData.external_fund_status.value !== null ||
                    allFilterData.risk_profile.value !== null || allFilterData.income_source.value !== null || allFilterData.kyc_status.value !== null || allFilterData.gender.value !== null || allFilterData.state.value !== null ||
                    allFilterData.city.value !== null || allFilterData.investment_status.value !== null || allFilterData.pyramid_status.value !== null || allFilterData.bank_verification_pending.value !== null) {
                    setFilterApplied(true);
                } else {
                    setFilterApplied(false);
                }
            } else {
                setCustomerData({});
            }
            setTableLoader(false);
            setFilter(false);
        })
    }

    useEffect(() => {
        if (filterEnabled === true) {

            setPageNumber(allFilterData.page_num);
            setAumFrom(allFilterData.aum_from);
            setAumTo(allFilterData.aum_to);
            setAgeFrom(allFilterData.age_from);
            setAgeTo(allFilterData.age_to);
            setFundStatus(allFilterData.external_fund_status);
            setPyramidStatus(allFilterData.pyramid_status);
            setRiskProfile(allFilterData.risk_profile);
            setSource(allFilterData.income_source);
            setKyc(allFilterData.kyc_status);
            setGender(allFilterData.gender);
            setState(allFilterData.state);
            setCity(allFilterData.city);
            setInvestmentStatus(allFilterData.investment_status);
            setBankVerificationPending(allFilterData.bank_verification_pending);
            setPhoneVerified(allFilterData.phone_verification_pending);

        }
        // eslint-disable-next-line
    }, [filterEnabled]);

    // API - get user summary
    const _getUserSummary = () => {
        const url = "/user/summary";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setSummaryData(response.data);
                setScreenLoader(false)
            }
        });
    }

    // API - list download
    const _downloadList = () => {
        setDownloadLoader(true);
        const selected_ids = new Set(selectedUsers);
        const selected_array = Array.from(selected_ids);
        const url = "/user/list-download";
        const request = {
            "user_ids": selected_array,
            "filter_data": {
                aum_from: allFilterData.aum_from.length === 0 ? null : allFilterData.aum_from,
                aum_to: allFilterData.aum_to.length === 0 ? null : allFilterData.aum_to,
                age_from: allFilterData.age_from.length === 0 ? null : allFilterData.age_from,
                age_to: allFilterData.age_to.length === 0 ? null : allFilterData.age_to,
                external_fund_status: allFilterData.external_fund_status === null ? null : allFilterData.external_fund_status.value,
                risk_profile: allFilterData.risk_profile === null ? null : allFilterData.risk_profile.value,
                income_source: allFilterData.income_source === null ? null : allFilterData.income_source.value,
                kyc_status: allFilterData.kyc_status === null ? null : allFilterData.kyc_status.value === 1 ? true : allFilterData.kyc_status.value === 2 ? false : null,
                gender: allFilterData.gender === null ? null : allFilterData.gender.value,
                state: allFilterData.state === null ? null : allFilterData.state.value,
                city: allFilterData.city === null ? null : allFilterData.city.value,
                investment_status: allFilterData.investment_status === null ? null : allFilterData.investment_status.value,
                pyramid_status: allFilterData.pyramid_status === null ? null : allFilterData.pyramid_status.value,
                query: allFilterData.query.trim(),
            }
        }
        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("List downloaded successfully", {
                    type: "success"
                });
                window.open(response.data.url, "_blank");
                setSelectedUsers(new Set());
                setSelectAll(false);
            } else {
                toast.error(response.message, {
                    type: "error"
                });
            }
            setDownloadLoader(false);
        });
    }

    // API - manage bank verification
    const _manageBankVerification = async () => {
        let status = false
        setPendingLoader(true);
        const url = isAccept ? "/user/approve-bank-verification" : "/user/reject-bank-verification";
        const request = {
            customer_id: bankStatus.user_id
        }
        await APIService(true, url, request).then((response) => {
            toast.dismiss()
            if (response.status_code === 200) {
                toast.success(isAccept ? "Bank verification approved" : "Bank verification rejected", {
                    type: 'success'
                })
                status = true;
                _getUserList();
            } else {
                toast.error(response.message, {
                    type: 'error'
                })
                status = false;
            }
            setPendingLoader(false);
        })
        return status
    }
    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Customer summary"
                    type={1} />
            </div>
            {
                screenLoader === true ?
                    <div className="min-h-100vh d-flex align-items-center justify-content-center">
                        <img src={require("../../../Assets/Images/loader/page-loader.gif")}
                            alt={"loader"}
                            width={60}
                            height={60}
                        />
                    </div>
                    :
                    <div className="e-body-wrapper">
                        <div className="row d-flex gap-24px">
                            <div className="col pe-0">
                                <InfoCard type={1}
                                    title="Total no. of users"
                                    data={summaryData.total_customer} />
                            </div>
                            <div className="col px-0">
                                <InfoCard type={1}
                                    title="Invested users"
                                    data={summaryData.invested_user}
                                />
                            </div>
                            <div className="col px-0">
                                <InfoCard type={1}
                                    title="Non-invested users"
                                    data={summaryData.non_invested_user}
                                />
                            </div>
                            <div className="col px-0">
                                <InfoCard type={1}
                                    title="KYC users"
                                    data={summaryData.kyc_user}
                                />
                            </div>
                            <div className="col ps-0">
                                <InfoCard type={1}
                                    title="Non-KYC users"
                                    data={summaryData.non_kyc_user}
                                />
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-12">
                                <div className="e-bg-white border-radius-32px pt-4 padding-40px-bottom">

                                    {/* header section */}
                                    <div className="px-4 mb-3">
                                        <div className="row d-flex align-items-center">
                                            <div className="col-md-1 col-12">
                                                <h4 className='mb-0 e-montserrat-semi-bold e-font-16 e-line-height-24'>
                                                    Customers
                                                </h4>
                                            </div>
                                            <div className="col-md-11 col-12">
                                                <div className="d-flex flex-wrap align-items-center justify-content-md-end justify-content-start">
                                                    {/* info and tooltip */}
                                                    <div className="py-2 px-3 me-3 position-relative">
                                                        {/* <div className={`${selectedUsers.size !== 0 ? "color-rebecca-purple e-montserrat-semi-bold" : "color-gray e-montserrat-regular"} cursor-pointer e-font-14 e-line-height-20  d-flex align-items-center`}
                                                            onMouseEnter={() => {
                                                                _handleTooltip(true)
                                                            }}
                                                            onMouseLeave={() => {
                                                                _handleTooltip(false)
                                                            }}>
                                                            <p className="mb-0">Create User Basket</p>
                                                            <Icon icon="circle-information" size="16px"
                                                                color={selectedUsers.size !== 0 && Colors.rebecca_purple}
                                                                className="margin-2px-left margin-2px-top" />
                                                        </div> */}
                                                        {
                                                            showTooltip &&
                                                            <div className="position-absolute e-font-14 e-montserrat-medium e-line-height-18 color-jett-black e-box-shadow-tooltip p-3 pe-0 border-radius-8px bg-white z-index-10 w-32vw"
                                                                onMouseEnter={() => {
                                                                    _handleTooltip(true)
                                                                }}
                                                                onMouseLeave={() => {
                                                                    _handleTooltip(false)
                                                                }}>
                                                                User baskets is used for sending notifications and reminders
                                                            </div>
                                                        }
                                                    </div>

                                                    {/* download customer reports */}
                                                    <DownloadButton
                                                        className="my-lg-0 my-3"
                                                        loader={downloadLoader}
                                                        onPress={_downloadList} />

                                                    {/* search users */}
                                                    <div className="col-lg-5 px-md-3 ps-0 pe-3">
                                                        <SearchInput value={search}
                                                            placeholder="Search with name, PAN or ID"
                                                            clear={() => {
                                                                _getUserList({ clearSearch: "" });
                                                                setSearch("");
                                                            }}
                                                            valueChange={_handleSearch}
                                                            handleSearch={(value) => {
                                                                setTableLoader(true);
                                                                _getUserList({ clearSearch: value })
                                                                _updateFilterData({ page_num: 1 })
                                                            }} />
                                                    </div>

                                                    {/* filter */}
                                                    <div className="position-relative">
                                                        <Icon icon={filterEnabled ? "filter-active" : "filter-inactive"}
                                                            size={42}
                                                            className={`${filterLoader === true ? "disabled" : "cursor-pointer"}`}
                                                            onClick={_handleToggleFilter} />
                                                        {
                                                            filterApplied === true &&
                                                            <span className={`position-absolute top-0 end-0 rounded-pill e-font-10 e-line-height-12 color-white e-montserrat-bold w-10px h-10px padding-1px-top d-flex align-items-center justify-content-center ${filterEnabled === true ? "e-bg-light-gray" : "e-bg-crimpson-red"}`}>
                                                            </span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Filter options */}
                                    {
                                        filterEnabled === true &&
                                        <div className="e-bg-light-gray padding-12px-tb padding-32px-lr margin-10px-bottom">
                                            <div className="row">

                                                {/* AUM filter */}
                                                <div className="col-3">
                                                    <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">AUM (Rs)</label>
                                                    <div className="row">
                                                        <div className="col-6 pe-0">
                                                            <div className="d-flex align-items-center">
                                                                <span className="e-montserrat-regular e-font-12 e-line-height-14 color-dark-charcoal margin-2px-right">From</span>
                                                                <CustomTextInput type="number"
                                                                    placeholder={0}
                                                                    value={aumFrom}
                                                                    min={0}
                                                                    valueChange={(input_value) => _handleAum(input_value, 1)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-6 ps-1">
                                                            <div className="d-flex align-items-center">
                                                                <span className="e-montserrat-regular e-font-12 e-line-height-14 color-dark-charcoal margin-2px-right">To</span>
                                                                <CustomTextInput type="number"
                                                                    value={aumTo}
                                                                    placeholder={100}
                                                                    valueChange={(input_value) => _handleAum(input_value, 2)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* external fund import status filter */}
                                                <div className="col-2">
                                                    <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">External fund status</label>
                                                    <CustomSelect value={fundStatus}
                                                        options={fundStatusOptions}
                                                        placeholder="All"
                                                        selectChange={_handleFundStatus} />
                                                </div>

                                                {/* risk profile filter */}
                                                <div className="col-2">
                                                    <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">Risk profile</label>
                                                    <CustomSelect value={riskProfile}
                                                        options={riskProfileOptions}
                                                        placeholder="All"
                                                        selectChange={_handleRiskProfile} />
                                                </div>

                                                {/* risk profile filter */}
                                                <div className="col-2">
                                                    <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">Source of income</label>
                                                    <CustomSelect value={source}
                                                        options={sourceOptions}
                                                        placeholder="All"
                                                        selectChange={_handleSourceIncome} />
                                                </div>

                                                {/* KYC status filter */}
                                                <div className="col-2">
                                                    <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">KYC status</label>
                                                    <CustomSelect value={kyc}
                                                        options={kycOptions}
                                                        placeholder="All"
                                                        selectChange={_handleKycStatus} />
                                                </div>
                                            </div>

                                            <div className="row padding-20px-top">

                                                {/* age filter */}
                                                <div className="col-3">
                                                    <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">Age</label>
                                                    <div className="row">
                                                        <div className="col-6 pe-0">
                                                            <div className="d-flex align-items-center">
                                                                <span className="e-montserrat-regular e-font-12 e-line-height-14 color-dark-charcoal margin-2px-right">From</span>
                                                                <CustomTextInput value={ageFrom}
                                                                    max={3}
                                                                    decimal={true}
                                                                    type="number"
                                                                    placeholder={18}
                                                                    valueChange={(input_value) => _handleAge(input_value, 1)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-6 ps-1">
                                                            <div className="d-flex align-items-center">
                                                                <span className="e-montserrat-regular e-font-12 e-line-height-14 color-dark-charcoal margin-2px-right">To</span>
                                                                <CustomTextInput value={ageTo}
                                                                    placeholder={100}
                                                                    max={3}
                                                                    decimal={true}
                                                                    valueChange={(input_value) => _handleAge(input_value, 2)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* gender filter */}
                                                <div className="col-2">
                                                    <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">Gender</label>
                                                    <CustomSelect value={gender}
                                                        options={genderOptions}
                                                        placeholder="All"
                                                        selectChange={_handleGender} />
                                                </div>

                                                {/* state filter */}
                                                <div className="col-2">
                                                    <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">State</label>
                                                    <CustomSelect value={state}
                                                        options={stateOptions}
                                                        placeholder="All"
                                                        selectChange={_handleState} />
                                                </div>


                                                {/* city filter */}
                                                <div className="col-2">
                                                    <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">City</label>
                                                    <CustomSelect value={city}
                                                        options={cityOptions}
                                                        isSearchable={true}
                                                        placeholder="All"
                                                        selectChange={_handleCity} />
                                                </div>

                                                {/* investment status filter */}
                                                <div className="col-2">
                                                    <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">Investment status</label>
                                                    <CustomSelect value={investmentStatus}
                                                        options={investmentStatusOptions}
                                                        placeholder="All"
                                                        selectChange={_handleInvestmentStatus} />
                                                </div>


                                            </div>
                                            <div className="row margin-20px-top align-items-end">
                                                {/* Pyramid filter */}
                                                <div className="col-3">
                                                    <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">Pyramid status</label>
                                                    <CustomSelect value={pyramidStatus}
                                                        options={pyramidStatusOptions}
                                                        placeholder="All"
                                                        selectChange={_handlePyramidStatus} />
                                                </div>
                                                <div className="col-3">
                                                    <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">Bank verification pending</label>
                                                    <CustomSelect value={bankVerificationPending}
                                                        options={bankVerificationOptions}
                                                        placeholder="All"
                                                        selectChange={_handleBankVerificationStatus} />
                                                </div>
                                                <div className="col-3">
                                                    <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">Phone verified</label>
                                                    <CustomSelect value={phoneVerified}
                                                        options={PHONE_VERIFIED_OPTIONS}
                                                        placeholder="No"
                                                        selectChange={_handlePhoneVerification} />
                                                </div>
                                                {/* apply filter */}
                                                <div className="col pt-3 d-flex gap-24px">


                                                    <PrimaryButton label="Filter now"
                                                        className="padding-14px-tb padding-38px-lr"
                                                        handleClick={_handleFilter} />


                                                    <SecondaryButton label="Clear"
                                                        className="padding-14px-tb padding-38px-lr"
                                                        handleClick={_handleClear} />

                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="position-relative">
                                        <div className="d-flex justify-content-end e-montserrat-semi-bold e-font-12 e-line-height-14 align-items-center margin-12px-bottom  padding-32px-lr ">
                                            <img src={require("../../../Assets/Images/DataTable/scroll.gif")}
                                                alt="scroll"
                                                width={24}
                                                height={24}
                                                className="me-1 rotate-90deg" />
                                            Scroll horizontally for view more  information
                                        </div>
                                        {
                                            tableLoader === true ?
                                                <div className="h-80vh d-flex align-items-center justify-content-center">
                                                    <img src={require("../../../Assets/Images/loader/page-loader.gif")}
                                                        alt={"loader"}
                                                        width={60}
                                                        height={60}
                                                    />
                                                </div>
                                                :
                                                Object.keys(customerData).length === 0 ?
                                                    <EmptyScreen className="my-5"
                                                        title="No customers found"
                                                        image={require("../../../Assets/Images/loader/empty-funds.png")}
                                                        description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                                    :
                                                    <Fragment>
                                                        <DataTableContainer data={customerData.data}
                                                            columns={USER_COLUMNS}
                                                            customStyles={TABLE_STYLE}
                                                            selectableRows={false}
                                                            paginationComponentOptions={{ noRowsPerPage: true }}
                                                            defaultSortFieldId={1}
                                                            pagination={true}
                                                            paginationServer={true}
                                                            perPage={12}
                                                            defaultSortAsc={false}
                                                            onChangePage={_handlePageChange}
                                                            paginationTotalRows={customerData.total_count}
                                                            paginationDefaultPage={customerData.current_page}
                                                            selectRows={(allSelected, selectedCount, selectedRows) => {
                                                            }}
                                                            rowClick={_handleNavigate}
                                                        />
                                                        <span className="e-montserrat-regular e-font-14 e-line-height-18 padding-24px-lr position-absolute color-jett-black bottom-10px">Showing {customerData.record} of {customerData.total_count} entries</span>
                                                    </Fragment>
                                        }
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
            }
            {
                bankStatus !== null &&
                <DeleteConfirmationModal title={isAccept ? "Approve Bank Verification" : "Reject Bank Verification"}
                    description={isAccept ? "Are you sure you want to approve this bank verification?" : "Are you sure you want to reject this bank verification?"}
                    label={isAccept ? "Approve" : "Reject"}
                    loader={pendingLoader}
                    document={bankStatus.bank_proof_url}
                    delete={_manageBankVerification}
                    close={() => _handleBankStatus(null, false)} />
            }

        </Fragment >
    )
}
export default CustomerListing;